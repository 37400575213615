import { Component, OnInit } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { Application, BridgeEventsService } from '@amc-technology/applicationangularframework';
import { bind } from 'bind-decorator';
import {
  IInteraction, IOperation, IScenario, Property, ValueType, IMetadata, IEventHandler,
  IInteractionUIHeaders
} from '@amc-technology/ui-library';
import { LoggerService } from '../logger.service';
import { ConfigurationService } from '../configuration.service';
import { IConfiguration } from '../model/IConfiguration';
import { NOTIFICATION_TYPE } from '@amc-technology/davinci-api';

@Component({
  selector: 'app-home',
  templateUrl: './home-salesforce-omni.component.html',
})
export class HomeSalesforceOmniComponent extends Application implements OnInit {
  static num = 0;
  protected scenarios: IScenario[];
  protected UIHeadersData: any;
  private objectFieldsMap: any;
  private config: IConfiguration;

  protected workItemPhoneNumberMap: {
    [workId: string]: {
      phoneNumber: string;
    };
  };

  constructor(private configService: ConfigurationService, private loggerService: LoggerService) {
    super(loggerService.logger);
    this.config = this.configService.config;
    this.objectFieldsMap = {};
    this.workItemPhoneNumberMap = {};
  }

  async ngOnInit() {
    try {
      this.loggerService.logger.logDebug('Salesforce Omni - Home : START : Fetching Salesforce Omni App Configuration');
      await this.loadConfig();
      let salesforceOrg = 'https://na53.salesforce.com';
      if (this.appConfig.variables['salesforceOrg'] !== undefined && this.appConfig.variables['salesforceOrg'] !== null
        && String(this.appConfig.variables['salesforceOrg']).length > 0) {
        salesforceOrg = String(this.appConfig.variables['salesforceOrg']);
      }

      this.bridgeScripts = this.bridgeScripts.concat([
        this.getBridgeURL(),
        salesforceOrg + '/support/api/47.0/interaction.js',
        salesforceOrg + '/support/console/47.0/integration.js',
        salesforceOrg + '/support/api/47.0/lightning/opencti_min.js'
      ]);

      await super.ngOnInit();
      this.loggerService.logger.logDebug('Salesforce Omni - Home : ngOnInit start');
      this.bridgeEventsService.subscribe('presenceStatusChanged', this.presenceStatusChanged);
      this.bridgeEventsService.subscribe('workAssigned', this.workAssigned);
      this.bridgeEventsService.subscribe('acceptAgentWorkFromSalesforce', this.acceptAgentWorkFromSalesforce);
      this.bridgeEventsService.subscribe('declineAgentWorkFromSalesforce', this.declineAgentWorkFromSalesforce);
      this.bridgeEventsService.subscribe('closeAgentWorkFromSalesforce', this.closeAgentWorkFromSalesforce);
      this.bridgeEventsService.subscribe('logoutFromSalesforce', this.logoutFromSalesforce);

      this.bridgeEventsService.subscribe('tabFocus', this.tabFocus);

      this.scenarios = [];
      api.registerOnPresenceChanged((presence: string, reason?: string, initiatingApp?: string) => {
        if (initiatingApp !== this.appConfig.name) {
          this.setPresence({ 'presence': presence, 'reason': reason });
        }
        return Promise.resolve();
      });
      api.registerOnLogout(async () => {
        this.logout();
        await this.logger.pushLogsAsync();
        return Promise.resolve();
      });

      api.registerOnInteraction((interaction: api.IInteraction): Promise<api.SearchRecords> => {
        this.onInteraction(interaction);
        return;
      });
      this.loggerService.logger.logDebug('Salesforce Omni - Home : END : Fetching Salesforce Omni App Configuration');
      await api.initializeComplete();
      this.login();
    } catch (err) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in ngOnInit. Error Details :` + err);
    }
  }

  @bind
  protected tabFocus(result) {
    this.scenarios.forEach(element => {
      const interaction = element.interactions[0];
      if (interaction['workItemId'] === result.objectId) {
        interaction['tabId'] = result.id;
      }
    });
  }
  protected async registerForAmcEvents() {
    try {
      this.loggerService.logger.logDebug('Salesforce Omni - Home : START : registerForAmcEvents Start');
      const setupPromises: Promise<any>[] = [
        api.loadBridgeScripts(this.bridgeScripts)
          .then(() => this.bridgeEventsService.sendEvent('afterScriptsLoad')),
      ];
      for (const promise of setupPromises) {
        await promise;
      }
      this.loggerService.logger.logDebug('Salesforce Omni - Home : END : registerForAmcEvents End');
      return;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce Omni - Home : ERROR : registerForAmcEvents ERROR. Error Details: ' + error);
    }
  }

  protected getInteraction(workId): IInteraction {
    try {
      this.loggerService.logger.logInformation('Salesforce Omni - Home : START : Retrieve scenario');
      for (let i = 0; i < this.scenarios.length; i++) {
        if (this.scenarios[i].interactions[0].interactionId === workId) {
          this.loggerService.logger.logDebug(`Salesforce Omni - Home : Retrieve scenario ${JSON.stringify(this.scenarios[i])}`);
          return this.scenarios[i].interactions[0];
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce Omni - Home : ERROR : Retrieve scenario. Error information : ' + error);
    }
  }

  protected isWorkItemDisplayed(workItemId) {
    try {
      this.loggerService.logger.logInformation('Salesforce Omni - Home : ERROR : Retrieve scenario.');
      for (let i = 0; i < this.scenarios.length; i++) {
        if (this.scenarios[i].interactions[0]['workItemId'] === workItemId) {
          return true;
        }
      }
      this.loggerService.logger.logInformation('Salesforce Omni - Home : ERROR : Retrieve scenario.');
      return false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce Omni - Home : ERROR : Retrieve scenario. Error information : ' + error);
    }
  }

  @bind
  protected setUIHeadersData(work): IInteractionUIHeaders {
    try {
      const type = work.workItem.type;
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Set UI headers for ${type}`);
      const UIHeadersData = {
        interactionTypeUrl: new URL(window.location.origin + '/assets/icons/' + type + '_notification.png'),
        minimizeUrl: new URL(this.config.iconPack + '/section_collapse.png'),
        maximizeUrl: new URL(this.config.iconPack + '/section_expand.png'),
        statusUrl: new URL(this.config.iconPack + '/Status_Ringing.png'),
        statusText: work.agentWork.Status,
        displayHoldCounter: false,
        directionText: 'Inbound',
        holdCounterData: {
          currentHoldStartTime: new Date(work.agentWork.AssignedDateTime).getTime()
        },
      };
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Set UI headers for ${type}`);
      return UIHeadersData;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Set UI headers. Error ` + error);
    }
  }

  @bind
  protected addScenario(scenario) {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Add scenario`);
      this.scenarios.push(scenario);
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Add scenario ${JSON.stringify(scenario)}`);
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERRPR : Add scenario. Error: ` + error);
    }
  }

  @bind
  protected removeScenario(workId) {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Remove Scenario Id ${workId}`);
      for (let i = 0; i < this.scenarios.length; i++) {
        if (this.scenarios[i].interactions[0].interactionId === workId) {
          this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Remove Scenario ${JSON.stringify(this.scenarios[i])}`);
          this.scenarios.splice(i, 1);
          break;
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR :
      Remove Scenario Id ${workId}. Error Information: ` + error);
    }
  }

  @bind
  protected setScenario(interaction): IScenario {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Set scenario`);
      const scenario = {
        interactions: [interaction]
      };
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Set scenario ${JSON.stringify(scenario)}`);
      return scenario;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Set scenario. Error Information: ` + error);
    }
  }

  @bind
  protected setInteraction(work, operations, properties, UIHeadersData): IInteraction {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Set Interaction`);
      const interaction = {
        interactionId: work.workId,
        operations: operations,
        associatedData: properties,
        UIHeadersData: UIHeadersData,
        startTime: new Date(work.agentWork.AssignedDateTime).getTime(),
        displayCallTimer: true,
        subheaderData: {
          image: new URL(window.location.origin + '/assets/icons/' + work.workItem.type + '_notification.png'),
          tooltip: work.workItem.type,
          value: work.workItem.type
        },
        workItemId: work.workItem.id,
        tabId: null
      };
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Set interaction ${JSON.stringify(interaction)}`);
      return interaction;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Set Interaction. Error Information` + error);
    }
  }

  @bind
  protected async presenceStatusChanged(presence: Object) {
    try {
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : Presence change to `
        + `${JSON.stringify(presence)} from Salesforce`, api.ERROR_CODE.PRESENCE_CHANGE);
      const appPresence = await api.getPresence();
      if (appPresence.presence !== presence['presence'] || appPresence.reason !== presence['reason']) {
        api.setPresence(presence['presence'], presence['reason']);
        this.loggerService.logger.logDebug(`Salesforce Omni - Home : Setting Presence change to `
          + `${JSON.stringify(presence)} from Salesforce`);
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Presence change to `
        + `${JSON.stringify(presence)} from Salesforce. Error Details : ` + error);
    }

  }

  @bind
  protected workAssigned(event) {
    try {
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : Work event recieved from bridge. Calling workAssigned`);
      const operations = this.createInitialOperations(event);
      const properties = this.createProperties(event);
      const UIHeadersData = this.setUIHeadersData(event);
      const interaction = this.setInteraction(event, operations, properties, UIHeadersData);
      const scenario = this.setScenario(interaction);
      if (!this.isWorkItemDisplayed(event.workItem.id)) {
        this.addScenario(scenario);
        api.sendNotification('Workitem update', NOTIFICATION_TYPE.Alert);
      }
      if (event.isEngaged) {
        this.changeUIOnAccept(interaction, event);
        api.sendNotification('Workitem update', NOTIFICATION_TYPE.Alert);
      }
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Work event recieved from bridge.`);
    } catch (error) {
      const msg = `Salesforce Omni - Home : ERROR : Error while assigning work in workAssigned! Exception details ${error.message}`;
      this.loggerService.logger.logError(msg, api.ERROR_CODE.Other);
      throw msg;
    }
  }

  @bind
  protected acceptAgentWorkFromSalesforce(event) {
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : acceptAgentWorkFromSalesforce
     Work item accept event received from bridge:` + ` ${JSON.stringify(event)}`);
    try {
      const scenario = this.getInteraction(event.workId);
      if (scenario && scenario.UIHeadersData.statusText !== event.agentWork.Status) {
        this.acceptOperationHandler('accept', this.buildOperationMetadata(event), false);
        api.sendNotification('Workitem update', NOTIFICATION_TYPE.Alert);
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : acceptAgentWorkFromSalesforce
      error details:` + error);
    }
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : acceptAgentWorkFromSalesforce
    Work item accept event received from bridge:` + ` ${JSON.stringify(event)}`);
  }

  @bind
  protected declineAgentWorkFromSalesforce(event) {
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : Work item decline event received from bridge:`
      + ` ${JSON.stringify(event)}`);
    try {
      const scenario = this.getInteraction(event.workId);
      if (scenario && scenario.UIHeadersData.statusText !== event.agentWork.Status) {
        this.declineOperationHandler('decline', this.buildOperationMetadata(event), false);
        api.sendNotification('Workitem update', NOTIFICATION_TYPE.Alert);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce Omni - Home : ERROR : Work item decline event error. Error Details : ' + error);
    }
  }

  @bind
  protected closeAgentWorkFromSalesforce(event) {
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : closeAgentWorkFromSalesforce event:`
      + ` ${JSON.stringify(event)}`);
    try {
      const scenario = this.getInteraction(event.workId);
      if (scenario && scenario.UIHeadersData.statusText !== event.agentWork.Status) {
        this.closeOperationHandler('close', this.buildOperationMetadata(event), false);
        api.sendNotification('Workitem update', NOTIFICATION_TYPE.Alert);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce Omni - Home : ERROR : closeAgentWorkFromSalesforce. Error Details : ' + error);
    }
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : closeAgentWorkFromSalesforce event:`
      + ` ${JSON.stringify(event)}`);
  }

  @bind
  protected logoutFromSalesforce() {
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : Logging out`);
    try {
      api.logout();
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error logging out. Error Details: ` + error);
    }
    this.loggerService.logger.logDebug(`Salesforce Omni - Home : END : Logging out`);
  }

  protected buildOperationMetadata(event): IMetadata[] {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Building Operation Metadata`);
    const operationMetadata: IMetadata = {
      key: event.agentWork.Id,
      value: event
    };
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Building Operation Metadata. Metadata : ` + operationMetadata);
    return [operationMetadata];
  }

  @bind
  protected acceptAgentWorkFromApp(work, fromApp) {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : acceptAgentWorkFromApp called:`
      + ` ${JSON.stringify(event)}`);
    if (fromApp) {
      this.bridgeEventsService.sendEvent('agentWorkAcceptedFromApp', work.workId);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : acceptAgentWorkFromApp :`
      + ` ${JSON.stringify(event)}`);
  }

  @bind
  protected declineAgentWorkFromApp(work, fromApp) {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : declineAgentWorkFromApp called:`
      + ` ${JSON.stringify(event)}`);
    if (fromApp) {
      this.bridgeEventsService.sendEvent('agentWorkDeclinedFromApp', work.workId);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : declineAgentWorkFromApp :`
      + ` ${JSON.stringify(event)}`);
  }

  @bind
  protected closeAgentWorkFromApp(work, fromApp) {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : closeAgentWorkFromApp called. Event Details :`
      + ` ${JSON.stringify(event)}`);
    try {
      if (fromApp) {
        const tabId = this.getInteraction(work.workId)['tabId'];
        this.bridgeEventsService.sendEvent('agentWorkClosedFromApp', { workId: work.workId, tabId: tabId });
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : closeAgentWorkFromApp error. Error Details :`
        + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : closeAgentWorkFromApp called. Event Details :`
      + ` ${JSON.stringify(event)}`);
  }

  @bind
  protected createInitialOperations(event): IOperation[] {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Creating initial operations. Event Details :`
      + ` ${JSON.stringify(event)}`);
    try {
      const operations_collection: IOperation[] = [];
      operations_collection.push({
        title: `Accept ${event.workItem.type}`,
        icon: new URL(this.config.iconPack + '/accept_work.png'),
        handler: this.acceptOperationHandler.bind(this),
        operationName: 'accept',
        operationMetadata: [{
          key: event.workId,
          value: event
        }]
      });
      if (event.workItem.type === 'Lead') {
        operations_collection.push({
          title: `AcceptDial ${event.workItem.type}`,
          icon: new URL(this.config.iconPack + '/accept_work_dial_opt.png'),
          handler: this.acceptDialOperationHandler.bind(this),
          operationName: 'accept and dial',
          operationMetadata: [{
            key: event.workId,
            value: event
          }]
        });
      }
      operations_collection.push({
        title: `Decline ${event.workItem.type}`,
        icon: new URL(this.config.iconPack + '/close_work.png'),
        handler: this.declineOperationHandler.bind(this),
        operationName: 'decline',
        operationMetadata: [{
          key: event.workId,
          value: event
        }]
      });
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Creating initial operations. Event Details :`
        + ` ${JSON.stringify(event)}`);
      return operations_collection;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Creating initial operations error. Event Details :`
        + error);
    }
  }

  @bind
  protected addCloseOperation(event) {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Close operation added to UI`);
    try {
      const operations: IOperation[] = this.getInteraction(event.workId).operations;
      operations.pop();
      operations.pop();
      operations.push({
        title: 'close',
        icon: new URL(this.config.iconPack + '/close_work.png'),
        handler: this.closeOperationHandler.bind(this),
        operationName: `Close ${event.workItem.type}`,
        operationMetadata: [{
          key: event.workId,
          value: event
        }]
      });
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Close operation added to UI`);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Close operation added to UI`);
  }

  @bind
  protected acceptDialOperationHandler(operationName: string, operationMetadata?: IMetadata[], fromApp = true): void {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : acceptDialOperationHandler ${operationName}`);
    try {
      if (operationMetadata) {
        operationMetadata.forEach(metadataElement => {
          this.loggerService.logger.logInformation(`Salesforce Omni - Home : ${operationName}: ${metadataElement.value}`);
          const workId = metadataElement.value['workId'];
          this.acceptAgentWorkFromApp(metadataElement.value, fromApp);
          const interaction: IInteraction = this.getInteraction(workId);
          this.changeUIOnAccept(interaction, metadataElement.value);


          for (const key in (metadataElement.value as any).workItem.displayAttributes) {
            if (key === 'Phone') {
              api.clickToDial((metadataElement.value as any).workItem.displayAttributes[key]);

            }
          }
        });
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Error in acceptDialOperationHandler. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : acceptDialOperationHandler ${operationName}`);
  }

  @bind
  protected acceptOperationHandler(operationName: string, operationMetadata?: IMetadata[], fromApp = true): void {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : acceptOperationHandler ${operationName}`);
    try {
      if (operationMetadata) {
        operationMetadata.forEach(metadataElement => {
          this.loggerService.logger.logInformation(`Salesforce Omni - Home : START :  ${operationName}: ${metadataElement.value}`);
          const workId = metadataElement.value['workId'];
          this.acceptAgentWorkFromApp(metadataElement.value, fromApp);
          const interaction: IInteraction = this.getInteraction(workId);
          this.changeUIOnAccept(interaction, metadataElement.value);
        });
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Error in acceptOperationHandler. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : acceptOperationHandler ${operationName}`);
  }

  @bind
  protected declineOperationHandler(operationName: string, operationMetadata?: IMetadata[], fromApp = true): void {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : declineOperationHandler ${operationName}`);
    try {
      if (operationMetadata) {
        operationMetadata.forEach(metadataElement => {
          this.loggerService.logger.logDebug(`Salesforce Omni - Home : ${operationName}: ${metadataElement.value}`);
          const workId = metadataElement.value['workId'];
          this.declineAgentWorkFromApp(metadataElement.value, fromApp);
          this.removeScenario(workId);
        });
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Error in declineOperationHandler. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : declineOperationHandler ${operationName}`);
  }

  @bind
  protected closeOperationHandler(operationName: string, operationMetadata?: IMetadata[], fromApp = true): void {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : closeOperationHandler ${operationName}`);
    try {
      if (operationMetadata) {
        operationMetadata.forEach(metadataElement => {
          this.loggerService.logger.logDebug(`Salesforce Omni - Home : ${operationName}: ${metadataElement.value}`);
          const workId = metadataElement.value['workId'];
          this.closeAgentWorkFromApp(metadataElement.value, fromApp);
          this.removeScenario(workId);
        });
      }
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Error in closeOperationHandler. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : closeOperationHandler ${operationName}`);
  }

  @bind
  protected changeUIOnAccept(interaction, work) {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Changing UI on Accept: Opened ${work.workItem.type}`);
    try {
      interaction.UIHeadersData.statusText = work.agentWork.Status;
      interaction.UIHeadersData.statusUrl = new URL(this.config.iconPack + '/Status_OnCall.png');
      this.addCloseOperation(work);
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : START : Error in changeUIOnAccept. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Changing UI on Accept: ${work.workItem.type}`);
  }

  @bind
  protected createProperties(event): Property[] {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Properties added to UI for ${event.workItem.type}`);
    try {
      const eventHadler: IEventHandler = {
        eventName: 'workItem : ' + event.workItem,
        handler: (eventName: string, eventMetadata?: IMetadata[]) => {
        }
      };
      const propertiesCollection: Property[] = [];
      for (const key in event.workItem.displayAttributes) {
        if (event.workItem.displayAttributes[key]) {
          propertiesCollection.push(new Property(this.objectFieldsMap[event.workItem.type][key], event.workItem.displayAttributes[key]));
        }
      }
      this.loggerService.logger.logDebug(`Salesforce Omni - Home : START :
      Properties added to UI for ${event.workItem.type}. Property Collection : ${propertiesCollection}`);
      return propertiesCollection;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in createProperties. Error Details :` + error);
    }
  }

  @bind
  protected createAssociatedData(prop1, prop2): Property[] {
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Create associted data. Params: ` + prop1 + ' ' + prop2);
    try {
      const propertiesCollection: Property[] = [];
      propertiesCollection.push(new Property(prop1, prop2));
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : Create associted data. Params: ` + prop1 + ' ' + prop2);
      return propertiesCollection;
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in createAssociatedData. Error Details :` + error);
    }
  }

  @bind
  protected setPresence(presence: Object) {
    try {
      if (presence['presence'] === 'Not Ready') {
        this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Remove all unopened work items `
          + `from UI on presence to not ready`, api.ERROR_CODE.PRESENCE_CHANGE);
        for (let i = 0; i < this.scenarios.length; i++) {
          if (this.scenarios[i].interactions[0].UIHeadersData.statusText !== 'Opened') {
            this.loggerService.logger.logDebug(`Salesforce Omni - Home : START : Remove scenario on presence change: `
              + `${this.scenarios[i]}`, api.ERROR_CODE.PRESENCE_CHANGE);
            this.scenarios.splice(i, 1);
          }
        }
      }
      this.bridgeEventsService.sendEvent('setPresence', presence);
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in setPresence. Error Details :` + error);
    }
  }

  @bind
  protected login() {
    this.loggerService.logger.logInformation('Salesforce Omni - Home : START : login.');
    try {
      api.getPresence().then(function (response) {
        this.bridgeEventsService.sendEvent('login', response);
      }.bind(this));
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in login. Error Details :` + error);
    }
    this.loggerService.logger.logInformation('Salesforce Omni - Home : END : login.');
  }

  @bind
  protected logout() {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Davinci logout`);
      this.bridgeEventsService.sendEvent('logout');
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in logout. Error Details :` + error);
    }
    this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : Davinci logout`);
  }

  protected async processConfig(config: api.IAppConfiguration) {
    try {
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : START : process config`);
      this.objectFieldsMap = config['ObjectFields'].variables;
      const bridgeSoftPhoneConfig = {
        onWorkClose: config.variables['CloseTabOnComplete'],
        objectFields: this.objectFieldsMap,
        presenceConfig: config['PresenceStatus'].variables
      };
      this.bridgeEventsService.sendEvent('bridgeSoftphoneConfig', bridgeSoftPhoneConfig);
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : Retrieve bridge softphone config `
        + `${JSON.stringify(bridgeSoftPhoneConfig)}`);
      this.loggerService.logger.logInformation(`Salesforce Omni - Home : END : process config`);
    } catch (error) {
      this.loggerService.logger.logError(`Salesforce Omni - Home : ERROR : Error in processConfig. Error Details :` + error);
    }
  }

  protected formatCrmResults(crmResults: any): api.SearchRecords {
    throw new Error('Not implemented!');
  }

  protected async getSearchLayout(): Promise<api.SearchLayouts> {
    throw new Error('Not implemented!');
  }


  protected async getUserInfoHandler(): Promise<string> {
    throw new Error('Not implemented!');
  }

  protected async isToolbarVisible(): Promise<boolean> {
    throw new Error('Not implemented!');
  }

  protected async saveActivity(activity: api.IActivity): Promise<string> {
    throw new Error('Not implemented!');
  }

  protected async onInteraction(interaction: api.IInteraction): Promise<api.SearchRecords> {
    this.loggerService.logger.logDebug(`AMCSalesforceHomeComponent: Interaction recieved: ${JSON.stringify(interaction)}`
      , api.ERROR_CODE.INTERACTION_EVENT);

    return;
  }
}
