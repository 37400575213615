import * as api from '@amc-technology/davinci-api';
import { Component, AfterViewChecked, OnInit, ElementRef } from '@angular/core';
import { bind } from 'bind-decorator';

/**
 * @ignore
 */
@Component({
  selector: 'app-root',
  templateUrl: './app-salesforce-omni.component.html',
  styleUrls: ['./app-salesforce-omni.component.css']
})
export class AppSalesforceOmniComponent implements OnInit, AfterViewChecked {
  height: number;
  el: ElementRef;
  constructor(el: ElementRef) {
    this.height = 0;
    this.el = el;
  }
  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.setHeight();
  }
  @bind
  private setHeight(): void {
    const newHeight = this.getHeight();
    if (newHeight !== this.height) {
      this.height = newHeight;
      api.setAppHeight(this.height);
    }
  }

  private getHeight(): number {
    const height = this.el.nativeElement.children[0].scrollHeight;
    return height;
  }
}

