import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppSalesforceOmniComponent } from './app-salesforce-omni.component';
import { HomeSalesforceOmniComponent } from './home/home-salesforce-omni.component';
import { UILibraryModule, IScenario } from '@amc-technology/ui-library';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';


@NgModule({
  declarations: [
    AppSalesforceOmniComponent,
    HomeSalesforceOmniComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    UILibraryModule,
    RouterModule.forRoot([
      { path: '', component: HomeSalesforceOmniComponent, pathMatch: 'full' }
    ])
  ],
  providers: [{
    // Here we request that configuration loading be done at app-
    // initialization time (prior to rendering)
    provide: APP_INITIALIZER,
    useFactory: (configService: ConfigurationService, loggerService: LoggerService) =>
      async () => {
        await configService.loadConfigurationData();
        await loggerService.initialize();
      },
    deps: [ConfigurationService, LoggerService],
    multi: true
  },
  ConfigurationService,
  LoggerService
  ],
  bootstrap: [AppSalesforceOmniComponent]
})
export class AppModule { }
